import { Identify, identify, setUserId, track } from '@amplitude/analytics-browser';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import * as Sentry from '@sentry/nextjs';

import { BROWSER_AMPLITUDE_ID } from '../globals';
import { getErrorMessage } from './getErrorMessage';
import { getSavedSsrABTestResult } from './getSsrABTest';
import { HERO_COPIES_SSR_AB } from './SsrABTests';

export interface AnalyticsEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

interface EventClick {
  path?: { attributes: { name: string; value: string }[] }[];
}

export const trackPageView = (): void => {
  (window as any).ga('send', 'pageview', window.location.pathname);
};

export const trackEvent = async (event: EventClick): Promise<void> => {
  const analyticsEvent = getAnalyticsEvent(event);

  if (analyticsEvent) {
    const { category, action, label } = JSON.parse(analyticsEvent);
    // google
    (window as any).ga('send', 'event', category, action, label);

    //amplitude
    track(action, { category, label });
  }
};

const getAnalyticsEvent = (event: EventClick): string => {
  let analyticsEvent: string;

  event.path?.forEach(element => {
    if (!element.attributes) return;
    (Array.from(element.attributes) || []).forEach(attribute => {
      if (typeof analyticsEvent === 'undefined' && attribute.name === 'data-analytics-event') {
        analyticsEvent = attribute.value;
      }
    });
  });

  return analyticsEvent;
};

export function trackDownloadClick(): void {
  try {
    trackGoogleTagManagerEvent({ event: 'clickedToAppStore' });
  } catch (error) {
    Sentry.captureException(
      'trackDownloadClick(): failed to track download click in google tag manager',
      {
        extra: { reason: error },
      },
    );
  }
}

export async function trackSignUp(params: {
  email: string;
  event: 'sign_up_email' | 'sign_up_apple' | 'sign_up_facebook' | 'sign_up_google';
  userId: string;
}): Promise<void> {
  try {
    // deprecated 'signUp', okay to remove february 17, 2023
    trackGoogleTagManagerEvent({ event: 'signUp', transactionId: params.userId });
    trackGoogleTagManagerEvent({ event: 'createdAccount', transactionId: params.userId });
  } catch (error) {
    Sentry.captureException('trackSignUpEvent(): failed to track signup in google tag manager', {
      extra: { reason: error },
    });
  }

  try {
    // https://stackoverflow.com/questions/65974337/import-es-module-in-next-js-err-require-esm
    const { changeUser, getUser, logCustomEvent, requestImmediateDataFlush } = await import(
      '@braze/web-sdk'
    );
    changeUser(params.userId);
    getUser().setEmail(params.email);
    logCustomEvent(params.event);
    requestImmediateDataFlush();
  } catch (error) {
    Sentry.captureException('trackSignUpEvent(): failed to track signup in braze', {
      extra: { reason: error },
    });
  }

  try {
    setUserId(params.userId);
    identify(new Identify().set('email', params.email));
  } catch (error) {
    Sentry.captureException('trackSignUpEvent(): failed to sign up in amplitude', {
      extra: { reason: error },
    });
  }
}

// copied over from web-app
function trackGoogleTagManagerEvent(params: {
  event: 'clickedToAppStore' | 'createdAccount' | 'signUp';
  transactionId?: string;
  conversionValue?: number;
}): void {
  (global.window as unknown as WindowWithGoogleTagManager).dataLayer.push(params);
}

type WindowWithGoogleTagManager = {
  dataLayer: Datalayer;
};

type Datalayer = {
  push: (event: { event: string; transactionId?: string; conversionValue?: number }) => boolean;
};

async function logEventWithProperties(
  event: string,
  properties: { [key: string]: unknown },
): Promise<void> {
  try {
    const { logCustomEvent } = await import('@braze/web-sdk');
    logCustomEvent(event, properties);
  } catch (error) {
    Sentry.captureException('logEventWithProperties(): failed to log in braze', {
      extra: { reason: error },
    });
  }

  try {
    await track(event, properties).promise;
  } catch (error) {
    Sentry.captureException('logEventWithProperties(): failed to log in amplitude', {
      extra: { reason: error },
    });
  }
}

export async function trackSignUpAttempt(
  method: 'email' | 'facebook' | 'apple' | 'google',
): Promise<void> {
  await logEventWithProperties('core_sign_up_attempt', {
    core_sign_up_method: method,
    core_sign_up_location: 'marketing',
  });
}
export async function trackSignUpError(
  method: 'email' | 'facebook' | 'apple' | 'google',
  error: unknown,
): Promise<void> {
  await logEventWithProperties('core_sign_up_error', {
    core_error_message: getErrorMessage(error),
    core_sign_up_method: method,
    core_sign_up_location: 'marketing',
  });
}
export async function trackSignUpSuccess(
  method: 'email' | 'facebook' | 'apple' | 'google',
): Promise<void> {
  await logEventWithProperties('core_sign_up_success', {
    core_sign_up_method: method,
    core_sign_up_location: 'marketing',
    [HERO_COPIES_SSR_AB.key]: getSavedSsrABTestResult(HERO_COPIES_SSR_AB),
  });
}

type AmplitudeExperimentClientRef = {
  client: ExperimentClient | null;
};

export const AMPLITUDE_EXPERIMENT_IDS = {
  DOWNLOAD_MAC_APP_TEST: 'marketing-mac-app-download',
};

export const AMPLITUDE_EXPERIMENT: AmplitudeExperimentClientRef = {
  client: null,
};

export async function initializeAmplitudeExperiments(): Promise<void> {
  try {
    const client = Experiment.initializeWithAmplitudeAnalytics(BROWSER_AMPLITUDE_ID);
    await client.start();
    AMPLITUDE_EXPERIMENT.client = client;
  } catch (error) {
    console.error('Failed to initialize Amplitude Experiment client', { reason: error });
  }
}
