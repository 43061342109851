import { Router, useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import {
  ANDROID_DOWNLOAD_LINK,
  IOS_DOWNLOAD_LINK,
  WEB_APP_SIGNIN_URL,
  WEB_APP_URL,
} from '../../globals';
import { useSignUpHandler } from '../../hooks/useSignUpHandler';
import { PromoBannerType } from '../../pages/api/promoBanner';
import { isLoggedIn } from '../../utils';
import { trackDownloadClick } from '../../utils/analytics';
import { DeviceTypes } from '../../utils/getDeviceType';
import { PartnerType } from './components/PartnerBanner';
import { trackDownloadMacAppClick } from './components/utils';
import { HeaderDisplay } from './Header.display';
import { useAmplitudeDownloadMacAppExperiment } from './hooks';

export function HeaderContainer(props: {
  deviceType: DeviceTypes;
  promoBanner: PromoBannerType | null;
}): JSX.Element {
  const onSignUp = useSignUpHandler();
  const isUserLoggedIn = isLoggedIn();

  const [partner, setPartner] = useState<PartnerType | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  // had to type cast because NextRouter doesn't have "components" property
  const router = useRouter() as Router;

  const { downloadBannerVisible, setDownloadBannerVisible } = useAmplitudeDownloadMacAppExperiment({
    partner,
    promoBanner: props.promoBanner,
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (router.pathname === '/[partner]') {
      const partnerComponent = router.components[router.pathname];
      setPartner(partnerComponent.props?.pageProps?.partner);
    } else {
      setPartner(null);
    }
  }, [router.components, router.pathname]);

  function handleCloseDownloadBanner() {
    setDownloadBannerVisible(false);
  }

  function handleScroll() {
    setIsScrolled(window.scrollY > 50);
  }

  function handleDownloadMacApp() {
    trackDownloadMacAppClick();
    handleCloseDownloadBanner();
    router.push('/download');
  }

  function handleClickSignIn() {
    window.location.href = WEB_APP_SIGNIN_URL;
  }

  function handleClickDownload() {
    trackDownloadClick();

    if (props.deviceType === DeviceTypes.Android) {
      window.location.href = ANDROID_DOWNLOAD_LINK;
    } else if (props.deviceType === DeviceTypes.IOS) {
      window.location.href = IOS_DOWNLOAD_LINK;
    }
  }

  const onNavigateToApp = useCallback(() => {
    window.location.href = WEB_APP_URL;
  }, []);

  const shouldHideHeaderActions = Boolean(partner);

  return (
    <HeaderDisplay
      deviceType={props.deviceType}
      downloadMacAppBannerVisible={downloadBannerVisible}
      headerActionsAvailable={!shouldHideHeaderActions}
      isDownloadPage={router.pathname === '/download'}
      isScrolled={isScrolled}
      isUserLoggedIn={isUserLoggedIn}
      partner={partner}
      promoBanner={props.promoBanner}
      onClickDownload={handleClickDownload}
      onClickSignIn={handleClickSignIn}
      onClickSignUp={onSignUp}
      onDownloadMacAppClose={handleCloseDownloadBanner}
      onDownloadMacAppNavigate={handleDownloadMacApp}
      onNavigateToApp={onNavigateToApp}
    />
  );
}
