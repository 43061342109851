// ... existing code ...

import { track } from '@amplitude/analytics-browser';
import { useContext, useEffect, useState } from 'react';

import { ModalDispatchContext } from '../../context/modalContext';
import { PromoBannerType } from '../../pages/api/promoBanner';
import { AMPLITUDE_EXPERIMENT, AMPLITUDE_EXPERIMENT_IDS } from '../../utils/analytics';
import { PartnerType } from './components/PartnerBanner';
/**
 * Attempts to get the experiment variant from Amplitude with retries
 * @param retries - Maximum number of retry attempts (default: 10)
 * @param interval - Time in milliseconds between retries (default: 200)
 * @returns Promise that resolves to the variant value or null if not available
 */
const getExperimentVariant = (retries = 10, interval = 200): Promise<string | null> => {
  return new Promise(resolve => {
    const attempt = () => {
      const variant = AMPLITUDE_EXPERIMENT.client?.variant(
        AMPLITUDE_EXPERIMENT_IDS.DOWNLOAD_MAC_APP_TEST,
      );

      if (variant || retries <= 0) {
        resolve(variant?.value || null);
        return;
      }

      retries--;
      setTimeout(attempt, interval);
    };

    attempt();
  });
};

type Props = {
  partner: PartnerType | null;
  promoBanner: PromoBannerType | null;
};

export const useAmplitudeDownloadMacAppExperiment = ({ partner, promoBanner }: Props) => {
  const [downloadBannerVisible, setDownloadBannerVisible] = useState(false);
  const [downloadMacTest, setDownloadMacTest] = useState<string | null>(null);
  const setModalState = useContext(ModalDispatchContext);

  useEffect(() => {
    const initExperiment = async () => {
      const variant = await getExperimentVariant();
      AMPLITUDE_EXPERIMENT.client?.exposure(AMPLITUDE_EXPERIMENT_IDS.DOWNLOAD_MAC_APP_TEST);

      track('test_download_impression', {
        variant,
      });

      setDownloadMacTest(variant);
    };

    initExperiment();
  }, []);

  useEffect(() => {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    if (isMac && downloadMacTest === 'banner' && !partner && !promoBanner) {
      const id = setTimeout(() => {
        setDownloadBannerVisible(true);
      }, 2000);

      return () => {
        clearTimeout(id);
      };
    }

    if (isMac && downloadMacTest === 'modal') {
      setModalState({ downloadMacAppVisible: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadMacTest, partner, promoBanner]);

  return { downloadBannerVisible, setDownloadBannerVisible };
};
